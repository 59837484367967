@import "~bootstrap/scss/bootstrap-utilities";

.header-img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // Mobile
    @include media-breakpoint-down(md) {
        filter: blur(10px);
    }
}

/*  3.0 ----------------- Page elements */
/* Page header */
.row-page-header {
	position: relative;
}

.row-page-header p + h1 {
	margin-top: -1.5rem;
}

.row-bg-header {
	height: 400px;
	position: relative;
}

.row-bg-header-img {
	width: 100%;
	height: 100%;
	position: absolute;
}

.row-bg-header-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}


/* Page wrapper */
.page-content {
	padding-top: 80px;
}


/* Lottie player */
.lottie-player {
	width: 100%;
	height: auto;
	margin-bottom: -1.5rem;
}


/* Stekkers */
.row-stekkers {
	position: relative;
}

.row-stekkers::before {
	left: 30px;
	top: -70px;
	height: 4px;
	content: " ";
	position: absolute;
	background: #46b5ee;
	width: calc(100% - 240px);
}

.row-stekkers::after {
	top: -96px;
	left: calc(100% - 216px);
	width: 30px;
	height: 30px;
	content: " ";
	position: absolute;

	border-left: 0;
	border-top: 0;
	border-bottom-right-radius: 10px;
	border-right: 4px solid #46b5ee;
	border-bottom: 4px solid #46b5ee;
}

.col-stekker {
	position: relative;
}

.col-stekker::before {
	top: -70px;
	left: 22px;
	width: 30px;
	height: 30px;
	content: " ";
	position: absolute;

	border-right: 0;
	border-bottom: 0;
	border-top-left-radius: 10px;
	border-top: 4px solid #46b5ee;
	border-left: 4px solid #46b5ee;
}

.col-stekker::after {
	left: 16px;
	top: -40px;
	width: 16px;
	height: 16px;
	content: " ";
	position: absolute;
	background: #3de1e9;
	border-radius: 6px 6px 1px 1px;
}


/* Ajax Search */
#products-result {
	display: none;
}

#filter {
	position: relative;
}

#filter span {
	top: 50%;
	right: 1rem;
	position: absolute;
	transform: translateY(-50%);
}

.product-search.position-fixed {
	top: -100%;
	width: 100%;
	z-index: 1060;
}

.product-search.position-fixed.sticky {
	top: 65px;
}

.product-search .form-control {
	border: none;
	padding: 1rem;
	line-height: 1;
	min-height: 50px;
	border-radius: 0;
	background-color: var(--wit);
}

/* Ajax search cards */
.product-group {
	position: relative;
}

.product-group span {
	top: 2.75rem;
	right: 2.5rem;
	cursor: pointer;
	position: absolute;
	transform: translateY(-50%) rotate(225deg);
}

.product-group.collapsed span {
	transform: translateY(-50%) rotate(0deg);
}

.product-group.bg-turquoise {
	background: rgba(26, 239, 200, 0.2);
}

.product-group h3 {
	width: 100%;
	top: 5.5rem;
	z-index: 1061;
	max-width: 50%;
	cursor: pointer;
	position: sticky;
	padding-left: 1rem;
	color: var(--zwart);
}

.product-group .card {
	padding: 0;
	border: none;
	background: none;
}

.product-group .card a {
	text-decoration: none;
	border: 1px solid var(--wit); 
}

.product-group .card a:hover {
	border-color: var(--donkergroen);
}

.product-group .card h4 {
	top: 2px;
	position: relative;
	padding-left: 2rem;
}

.product-group .card a:hover h4 {
	color: var(--donkergroen);
}

.product-group .card h4 .icon {
	top: 40%;
	left: -0.5rem;
	font-size: 38px;
	position: absolute;
	transform: translateY(-50%);
}

.product-group .card a:hover h4 .icon {
	color: var(--groen);
}

.product-return {
	left: -5.25rem;
	font-size: 14px;
	position: relative;
	padding-left: 1.75rem;
	text-decoration: none !important;
	font-family: "AlmarenaDisplayBold";
}

.product-return span {
	left: 0;
	top: 50%;
	position: absolute;
	transform: translateY(-55%);
}


/* Cases */
.col-swiper {
	padding-right: 0;
	padding-left: calc(30px + (100% - 1170px) / 2);
}

.container-swiper .swiper-slide-next + .swiper-slide {
	opacity: 0.333;
}

.swiper-cases .swiper-clients,
.swiper-cases .swiper-slide {
	height: auto;
	width: 350px;
}

.swiper-clients.logos .swiper-slide {
	height: auto;
	width: 280px;
}


	/* Navigation */
	.swiper-navigation {
		top: -5rem;
		z-index: 100;
		position: absolute;
		right: calc(15px + (100vw - 1140px) / 2);
	}

	.swiper-btn {
		width: 30px;
		height: 30px;
		text-align: center;
		position: relative;
		color: var(--blauw);
		border-radius: 30px;
		background: var(--wit);
		border: 1px solid var(--blauw);
	}

	.swiper-btn span {
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
	}

	.swiper-btn:hover,
	.swiper-btn.swiper-prev {
		cursor: pointer;
		color: var(--wit);
		background: var(--blauw);
	}

	/* Testimonials */
	.swiper-testimonials .swiper-wrapper {
		border-radius: 200px;
	}

	.swiper-testimonials-navigation {
		top: 0 !important;
		left: auto !important;
		right: auto !important;
		position: relative !important;
		transform: translateY(-50%) !important;
	}

	.card-testimonial {
		border: 0;
		border-radius: 0;
		padding: 2rem 3rem;
		background: transparent;
	}


	/* Cards */
	.swiper-clients .swiper-slide,
	.swiper-cases .swiper-slide {
		padding: 10px 10px 10px 0;
	}

	.swiper-cases .swiper-slide .card {
		box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
	}

	.card-case,
	.bg-light .swiper-cases .swiper-slide .card {
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.025);
	}

	.card-case,
	.card-case .card-img-bottom {
		border: 0;
		border-radius: 0;
		
	}

	.card-case .card-body {
		padding: 0;
	}

	.card-case .card-body p {
		margin: 0;
		font-size: 16px;
		font-family: "AlmarenaRegular";
	}

	.card-vacature .case-intro {
		padding: 0;
		border: none;
	}

	.card-vacature .card-body p {
		font-family: "AlmarenaRegular";
	}

	.card-case a .card-body p,
	.card-case a:hover .card-body p {
		font-size: 16px;
		line-height: 1.5;
		color: var(--zwart);
		text-decoration: none;
	}

	.card-case .card-footer {
		border: none;
		background: none;
		border-radius: 0;
	}

	/* Logo */
	.card-case .case-logo {
		width: 100%;
	}

	.card-case .case-logo img {
		padding: 1.5rem;
	}


	/* Intro */
	.case-intro {
		padding: 1.5rem;
		border-top: 1px solid var(--grijs);
	}


	/* Afbeeldingen */
	.case-logo {
		width: 200px;
		min-width: 200px;
	}

	.case-logo.border {
		min-width: 1px;
		width: auto !important;
	}

	.case-logo.border {
		max-width: 100%;
		border: 2px solid var(--zwart) !important;
	}

	.case-logo.border img {
		width: auto;
		height: 50px;
	}


/* Label */
.card-label {
	right: 0;
	font-size: 12px;
	position: absolute;
	padding: 0.25rem 0.5rem;
	transform: translateY(-50%);
	border-radius: 25px 0 0 25px;
	font-family: "AlmarenaDisplayBold";
}


	/* Tag */
	.row-case-info {
		bottom: -31px;
	}

	.case-tag {
		line-height: 0;
		letter-spacing: 0.1rem;
		font-family: "AlmarenaDisplayBold";
	}



/* Oplossingen */
.oplossingen ul {
	padding: 0;
	margin-top: 0;
	list-style: none;
}

.oplossingen ul li {
	line-height: 1.7;
	position: relative;
	padding-left: 2rem;
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;	
}

.oplossingen ul li::before {
	top: 0;
	left: 0;
	font-size: 28px;
	content: "\e901";
	line-height: 1.0;
	position: absolute;
	font-family: icomoon;
	background: -webkit-linear-gradient(var(--paars), var(--blauw));
	
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.row-oplossing {
	margin-top: 2rem;
}

.row-oplossing .col-oplossing {
	padding-bottom: 1.75rem;
	border-bottom: 2px solid rgba(var(--rgbzwart), 0.075);
}

.bg-gradient-dark .row-oplossing .col-oplossing {
	border-bottom: 2px solid rgba(var(--rgbwit), 0.15);
}

.row-oplossing:last-of-type .col-oplossing {
	border-bottom: none !important;
}


/* Partners */
img.partner-logo {
	height: auto;
	max-width: 200px;
}

img.partner-logo:not(:last-of-type) {
	margin-right: 1rem;
}


/* Afbeeldingen */
.img-stekker {
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: calc((1140px / 12 * 4) + ((100% - 1140px) / 2 - 30px));
}

.img-stekker img {
	float: right;
	height: 100%;
	max-height: 300px;
	object-fit: cover;
	object-position: center right;
}


/* Glitch and Lottie's */
.glitch {
	height: 160px;
	overflow: hidden;
	position: relative;
}

.glitch video,
.bibliotheek video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}


/* Modules */
.row-module {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.row-module:last-of-type {
	padding-bottom: 6rem;
}

.row-module.bg-wit + .row-module.bg-wit {
	padding-top: 0;
}


/* Frontpage */
.row-frontpage h3 {
	margin-bottom: 1.5rem;
}


/* Oplossingen */
.sticky-oplossingen {
	top: 65px;
}

.row-oplossingen .row.min-vh-100 {
	min-height: 100% !important;
	padding-top: calc(137px + 3rem);
}

.row-module-koppelingen {
	margin-bottom: -6rem;
	padding-bottom: 9rem !important;
}


/* Powerbox */
.row-powerbox {
	padding-bottom: 0;	
}


/* Vacatures */
.row-vacature {
	padding-top: 0;
	margin-top: -4.5rem;
}






/*  6.0 ----------------- Footer */
/* Footer */
footer .container p {
	margin-bottom: 1rem;
}

footer .container p:last-of-type {
	margin-bottom: 0;
}

footer .container p a {
	text-decoration: underline;
}


/* Ticker */
.ticker {
	overflow: hidden;
	position: relative;
}

.ticker .swiper-wrapper {
	-webkit-transition-timing-function: linear!important; 
	-o-transition-timing-function: linear!important;
	transition-timing-function: linear!important; 
}

.ticker .swiper-wrapper .swiper-slide {
	width: auto !important;
}

.ticker .swiper-wrapper .swiper-slide span {
	position: relative;
	padding: 0 0 0 3rem;
	text-transform: uppercase;
	font-family: "AlmarenaDisplayBold";
}

.ticker .swiper-wrapper .swiper-slide span.icon {
	top: 50%;
	padding: 0;
	left: 0.85rem;
	font-size: 24px;
	position: absolute;
	transform: translateY(-55%);
}

.ticker-title {
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 2;
	position: absolute;
	box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.25);
}


/* Social media */
.social-icons {
	margin-top: 1rem;
}

.social-icons a {
	text-decoration: none;
}

.social-icons a span {
	font-size: 26px;
	color: var(--wit);
}

.social-icons a:not(:last-of-type) {
	margin-right: 1rem;
}

.bg-wit .social-icons a span {
	color: var(--zwart);
}


.btn.btn-order span {
	top: 50%;
	right: 4px;
	display: flex;
	height: calc(100% - 8px);
	color: var(--wit);
	aspect-ratio: 1/1;
	position: absolute;
	border-radius: 100px;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);
	background-color: rgba(0, 0, 252, 1);
}


@media only screen and (max-width: 1200px) {
	
	.row-stekkers::after {
		left: calc(100% - 186px);
	}
	
	.row-stekkers::before {
		width: calc(100% - 195px);
	}
	
	
	/*  0.0 ----------------- Defaults */	
	/* Buttons */	
	.btn-internal {
		font-size: 16px;
	}
	
	
	/* Dots */
	.bg-dots {
		max-width: 25vw;
	}
	
	
	/*  1.0 ----------------- Typografie */
	/* Basics */
	body p {
		font-size: 16px;
	}
	
	
	/* Headings */
	h1, .h1 {
		font-size: 42px;
	}

	h2, .h2 {
		font-size: 28px;
	}
	
	h3, .h3 {
		font-size: 20px;
	}
	
	h4, .h4 {
		font-size: 18px;
	}
	
	
	/*  3.0 ----------------- Page elements */
	/* Cases */
	.swiper-clients,
	.swiper-cases {
		padding-left: 25px;
	}


		/* Navigation */
		.swiper-navigation {
			right: 15px;
			top: -3.5rem;
		}
	
		
		/* Cards */
		.card-blog .card-body {
			padding-top: 1rem;
		}

		.card-blog .card-body h3 {
			font-size: 18px;
		}
		
	
}


@media only screen and (max-width: 992px) {
	
	
	.col-stekker-img {
		text-align: center;
	}
	
	.col-stekker-img img {
		max-width: 200px;
	}
	
	.row-stekkers::after {
		top: -45px;
		left: 50%;
		transform: translateX(-83.333%);
	}
	
	.row-stekkers::before {
		top: -19px;
		left: 5px;
		border-top-left-radius: 10px;
		width: calc(50% - 10px);
	}
	
	.col-stekker {
		margin-left: 5px;
		padding-left: 45px;
		border-left: 4px solid #46b5ee;
	}
	
	.col-stekker:last-of-type {
		border-left: 0;
	}
	
	.col-stekker:last-of-type::before {
		left: 0;
	}
	
	.col-stekker:last-of-type::after {
		left: 15px;
	}
	
	.col-stekker::before {
		top: -15px;
		left: -4px;
		border-top: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 10px;
		border-bottom: 4px solid #46b5ee;
	}
	
	.col-stekker::after {
		top: 4.5px;
		left: 11px;
		border-radius: 1px 6px 6px 1px;
	}
	
	
	/*  3.0 ----------------- Page elements */
	/* Page wrapper */
	.page-content {
		padding-top: 65px;
	}
	
	
}



@media only screen and (max-width: 768px) {
	
	.col-stekker-img {
		text-align: center;
	}
	
	.col-stekker-img img {
		max-width: 200px;
	}
	
	.row-stekkers::after {
		top: -45px;
		left: 50%;
		transform: translateX(-83.333%);
	}
	
	.row-stekkers::before {
		top: -19px;
		left: 5px;
		border-top-left-radius: 10px;
		width: calc(50% - 10px);
	}
	
	.col-stekker {
		margin-left: 5px;
		padding-left: 45px;
		border-left: 4px solid #46b5ee;
	}
	
	.col-stekker:last-of-type {
		border-left: 0;
	}
	
	.col-stekker:last-of-type::before {
		left: 0;
	}
	
	.col-stekker:last-of-type::after {
		left: 15px;
	}
	
	.col-stekker::before {
		top: -15px;
		left: -4px;
		border-top: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 10px;
		border-bottom: 4px solid #46b5ee;
	}
	
	.col-stekker::after {
		top: 4.5px;
		left: 11px;
		border-radius: 1px 6px 6px 1px;
	}
	
	
	/*  0.0 ----------------- Defaults */	
	/* Buttons */
	.btn {
		font-size: 14px;
	}
	
	/*
	.btn-internal {
		flex: auto;
		text-align: left;
		padding-left: 0;
		padding-right: 3rem;		
	}
	
	.btn-internal:last-of-type {
		padding-right: 25px;
		/*padding-right: calc(100vw - 110px);
	}
	*/
	
	
	/*  1.0 ----------------- Typografie */
	/* Basics */
	body p {
		font-size: 16px;
		line-height: 1.8;
		margin-bottom: 1rem;
	}


		/* List items */
		body ul,
		body ol {
			font-size: 15px;
			line-height: 1.8;
			padding-left: 1.25rem;
		}
	
		body p + ul,
		body p + ol {
			margin-top: -0.5rem;
		}
	
		/* Blockquote */
		body blockquote {
			margin: 2rem auto;
			padding: 1.5rem 1.75rem;
		}
	
		/* Image */
		body p img.img-fluid.size-full {
			margin-left: -25px;
			max-width: calc(100% + 50px);
		}
	

	/* Headings */
	h1, .h1 {
		font-size: 38px;
	}

	h2, .h2 {
		font-size: 30px;
	}
	
	h3, .h3 {
		font-size: 20px;
	}
	
	h4, .h4 {
		font-size: 20px;
	}

	
	
	.dropdown-toggle::after {
		right: -1.5rem;
	}
	
	.navbar-nav .dropdown-menu {
		text-align: center;
		margin-bottom: 1rem;
	}
	
		
	ul .wpml-ls-menu-item a {
		justify-content: center;
	}
	
	
	
	/*  3.0 ----------------- Page elements */
	/* Page header */
	.row-bg-header {
		height: 215px;
		position: relative;
	}
	
	
	/* Page wrapper */
	.page-content {
		padding-top: 65px;
	}

	
	/* Cases */
	.case {
		position: relative;
	}
	
	.container-swiper {
		padding-left: 0;
	}
	
	.container-swiper .swiper-wrapper {
		padding-left: 25px;
	}
	
	
		/* Navigation */
		.swiper-navigation {
			padding-right: 10px;
		}


		/* Cards */	
		.card-case a .card-body p,
		.card-case a:hover .card-body p {
			font-size: 14px;
		}
	
		.card-testimonial {
			padding: 1.5rem;
		}

	
	/* Skews */
	.skew-recht-bottom {
		padding-bottom: 3rem !important;
	}
	
	.skew-left,
	.row-module.skew-left {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
		clip-path: polygon(0 2.5%, 100% 0, 100% 97.5%, 0% 100%);
	}

	.skew-right,
	.row-module.skew-right {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
		clip-path: polygon(0 0, 100% 2.5%, 100% 100%, 0 97.5%);
	}
	
	.skew-left-top,
	.row-module.skew-left-top {
		padding-top: 3rem !important;
		clip-path: polygon(0 2.5%, 100% 0, 100% 100%, 0% 100%);
	}
	
	.skew-right-top,
	.row-module.skew-right-top {
		padding-top: 3rem !important;
		clip-path: polygon(0 0, 100% 2.5%, 100% 100%, 0 100%);
	}
	
	.skew-left-bottom,
	.row-module.skew-left-bottom {
		padding-bottom: 3rem !important;
		clip-path: polygon(0 0, 100% 0, 100% 97.5%, 0% 100%);
	}
	
	.skew-right-bottom,
	.row-module.skew-right-bottom {
		padding-bottom: 3rem !important;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97.5%);
	}
	
	
	/* Diensten */
	.row-module-koppelingen {
		padding-top: 4.5rem !important;
		margin-bottom: -3rem !important;
		padding-bottom: 6rem !important;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 98.75%) !important;
	}
	
	
	/* Cases */
	.row-module-cases {
		margin-bottom: 3rem;
	}
	
	
	/* Oplossingen */	
	.oplossingen ul li {
		font-size: 14px;
		padding-right: 1rem;
	}
	
	.sticky-oplossingen {
		overflow-x: auto;
  		white-space: nowrap;
	}
	
	.sticky-oplossingen .container::-webkit-scrollbar {
		display: none;
	}
	
	.sticky-oplossingen .container {
		overflow-x: scroll;
		
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	
	.row-oplossingen .row.min-vh-100 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
	
	
	/* Vacatures */
	.row-vacature {
		padding-top: 0;
		margin-top: -6rem;
	}

	
	/* Modules */
	.row-module {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.row-module:last-of-type {
		padding-bottom: 3rem;
	}
	
	
	/* Afbeeldingen */
	.case-logo {
		width: 100px;
		min-width: 100px;
	}
	
	.case-logo.border {
		min-width: 1px;
		width: auto !important;
	}
	
	.case-logo.border img {
		max-height: 30px;
	}


	/* Tag */
	.row-case-info {
		bottom: -21px;
	}
	
	

	/*  6.0 ----------------- Footer */	
	/* Footer */
	.footer-col {
		width: 50% !important;
	}
	
	.footer-logo {
		height: auto;
		max-width: 150px;
	}
	
	/* Ticker */
	.ticker {
		font-size: 13px;
	}
	
	/* Overlay */
	.footer-overlay {
		top: -100px;
		height: calc(100% + 100px);
		clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
	}
	
	
}



@media only screen and (max-width: 576px) {
	
	/*.row-powerbox .container {
	/*	overflow: visible;
	/* } */	
	
	.product-group h3 {
		top: 0;
		z-index: 1;
		font-size: 16px;
		max-width: 83.333%;
		padding-left: 0.5rem;
	}
	
	.product-group .card h4 {
		font-size: 13px;
	}
	
	.product-group span {
		top: 2rem;
		right: 1rem;
	}

	/*  0.0 ----------------- Defaults */
	/* Bootstrap edits */
	.container {
		overflow-x: hidden;
		overflow-y: hidden;
		padding-left: 25px;
		padding-right: 25px;
	}
	
	
	/*  1.0 ----------------- Typografie */
	/* Headings */
	h1, .h1 {
		font-size: 32px;
	}
	
	h2, .h2 {
		font-size: 26px;
	}
	
	h2.text-large,
	.h2.text-large {
		font-size: 26px;
	}
	
	
}


/* EXTRA: Sparse Powerconnector */
/* Bootstrap edits */
.row-vh-75 {
	min-height: 75vh;
}

.text-boxshadow {
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.025)
}


/* Forms */
.form-top input[type="text"].form-control {
	border: 0;
	height: auto;
	font-size: 20px;
	min-height: 1px;
	background: none;
	font-weight: 700;
	color: var(--groen);
	padding: 0.333rem 0.25rem 0.25rem;
	border-bottom: 2px dotted var(--zwart);
}

.form-top input[type="text"].form-control::placeholder {
  color: var(--groen);
  opacity: 1;
}

.form-top input[type="text"].form-control::-ms-input-placeholder {
  color: var(--groen);
}

.form-bottom .form-check,
#filter .form-check {
	display: flex;
	align-items: center;
}

.form-bottom .form-check-input,
#filter .form-check-input {
	margin-top: 0;
}

.form-bottom .form-check label {
	font-size: 20px;
	cursor: pointer;
}

.form-search .form-control {
	margin-bottom: 0;
}



/* Footer with USPs */
.row-footer-usps .col-12:not(:last-of-type)::before {
	top: 50%;
	right: 0;
	font-size: 24px;
	content: "\e901";
	color: var(--wit);
	position: absolute;
	transform: translate(50%, -50%);
	font-family: 'icomoon' !important;
}


/* Bootstrap modal */
.modal {
	z-index: 1080;
}

.img-unavailable {
	top: 0;
	left: 0;
	max-width: 100px;
	position: absolute;
	transform: translate(-33.333%, -33.333%) rotate(-7deg);
}

	/* Backdrop */
	.modal-backdrop {
		z-index: 1070;
	}

	.modal-backdrop.show {
		opacity: 0.833;
	}

	/* Content */
	.modal-content {
		border: 0;
		border-radius: 0;
	}

	.modal-content p {
		font-size: 15px;
	}

	.close {
		text-shadow: none;
	}


/* Product card */
.card-product {
	box-shadow: none !important;
}

.card-product-overflow {
	overflow: hidden;
}

	/* Image */
	.card-product .product-image-wrapper {
		height: 200px;
	}

	.card-product .product-image img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.card-product .product-image-wrapper,
	.card-product .product-image img {
		z-index: 2;
		position: relative;
		border-radius: 10px;
	}

	.card-product .product-image {
		border-radius: 20px;
		position: relative;
		padding-right: 4rem !important;
		box-shadow: inset -2rem 0 0 0 rgba(0, 0, 252, 0.2), inset 1rem 0 0 0 rgba(0, 0, 252, 0.05);
	}

	.card-product .product-image::after {
		top: 50%;
		right: 1rem;
		left: auto;
		width: 12px;
		content: " ";
		height: 25%;
		position: absolute;
		border-radius: 50px;
		transform: translate(50%, -50%);
		background: rgba(0, 0, 255, 0.2);
	}

	.card-product .product-image::before {
		border-radius: 20px;
	}

	/* Labels */
	.product-label {
		z-index: 3;
		position: absolute;
	}

		/* New */
		.product-label.product-new {
			top: 0;
			right: 0;
			width: 100px;
			display: flex;
			font-weight: 700;
			aspect-ratio: 1/1;
			color: var(--wit);
			align-items: center;
			justify-content: center;
			background: var(--paars);
			text-transform: uppercase;
			transform: translate(33.333%, -33.333%) rotate(15deg);

			// -webkit-mask-image: url('/assets/img/sparse-icon-ster.svg');
			// mask-image: url('/assets/img/sparse-icon-ster.svg');

			-webkit-mask-size: 100% 100%;
			mask-size: 100% 100%;

			-webkit-mask-position: center;
			mask-position: center;

			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
		}
	
		/* Favorite */
		.card-product .product-label.product-favorite img {
			width: 28px;
			height: 28px;
			object-fit: contain;
		}

		.product-label.product-favorite {
			bottom: 4rem;
			left: 3rem;
			font-size: 14px;
			padding: 0.25rem 0.5rem 0.25rem 1rem;
			transform: translateY(-50%);
			border-radius: 0 25px 25px 0;
			background: var(--turquoise);
		}

		/* Coming soon */
		.product-label.product-soon {
			bottom: 4rem;
			left: 50%;
			width: 110%;
			font-size: 14px;
			color: var(--wit);
			text-align: center;
			padding: 0.25rem 2rem;
			background: var(--paars);
			transform: rotate(9deg) translateX(-50%);
		}

		/* Unavailable */
		.card-unavailable .product-image {
			opacity: 0.333;
		}




		


@media only screen and (max-width: 1200px) {
	
	/* Bootstrap edits */
	.row-vh-75 {
		min-height: 1px;
	}
	
	/* Product card */
	.card-product .product-image {
		box-shadow: inset -2rem 0 0 0 rgba(0, 0, 252, 0.2), inset 0.75rem 0 0 0 rgba(0, 0, 252, 0.05)
	}
	
	.product-label.product-favorite {
		left: 1.5rem;
	}

}


@media only screen and (max-width: 768px) {
	
	/* Forms */
	.form-top {
		flex-direction: column;
	}
	
	.form-top input[type="text"].form-control {
		text-align: center;
		margin: 1rem 0 1rem 0 !important;
	}
	
	.form-bottom .form-check label {
		font-size: 15px;
	}
	
	
	/* Footer with USPs */
	.row-footer-usps .col-12:not(:last-of-type) {
		margin-bottom: 1rem;
		padding-bottom: 1rem;
	}
	
	.row-footer-usps .col-12:not(:last-of-type)::before {
		left: 50%;
		top: auto;
		bottom: 0;
		transform: translate(-50%, 50%);
	}
	
}
