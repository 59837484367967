/*  2.0 ----------------- Header and navigation */
/* Branding */
.navbar .navbar-brand img {
	top: 7.5px;
	width: auto;
	height: 125px;
}


/* Navbar */
.navbar.position-absolute {
	top: 0;
	z-index: 1060;
}

.navbar.position-absolute.small {
	height: 80px;
}

.navbar.fixed-top {
	opacity: 0;
	top: -200px;
	height: 65px;
}


/* Sticky navbar */
.navbar.fixed-top.sticky {
	top: 0;
	opacity: 1;
	z-index: 1070;
	box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.05);
}

.navbar.sticky .navbar-brand img {
	height: 97px;
}

.navbar.fixed-top.sticky .navbar-toggler {
	top: 0;
	right: 5px;
}


/* Navigation */
.navbar-nav .nav-item {
	margin-bottom: 0;
}

.navbar-nav .nav-item:not(:first-of-type) {
	margin-left: 1rem;
}

.navbar-nav .nav-item .nav-link {
	padding: 0 1rem;
	font-size: 18px;
	line-height: 80px;
	color: var(--zwart);
	font-family: "AlmarenaDisplayBold";
}

.navbar-nav .nav-item.dropdown .nav-link {
	padding-right: 2rem;
	outline: none !important;
}


.navbar-nav .nav-item:hover .nav-link {
	background: var(--turquoise);
}

.navbar-nav .nav-item.active .nav-link,
.navbar-nav .nav-item.current-page-ancestor .nav-link {
	text-decoration: underline;
}

.navbar.fixed-top .navbar-nav .nav-item .nav-link {
	line-height: 65px;
}

/* Navbar */
.navbar .navbar-brand img.sparse-powerconnector {
	left: 0;
	top: 50%;
	width: auto;
	height: 150px;
	transform: translate(-50%, -41.667%);
}

.navbar .navbar-brand .sparse-title {
	padding-left: 65px;
}


/* WPML */
.wpml-ls-current-language {
	position: relative;
	margin-left: 1.25rem !important;
	
	button.dropdown-toggle.nav-link {
		display: inline-block;
		align-items: center;
		background: transparent !important;
		border: none;
		color: var(--zwart);
		font-size: 12px;
		font-family: "AlmarenaDisplayRegular";
	}
	.dropdown-menu.show {
		display: inline-block;
		text-align: center;
	}
}

.wpml-ls-current-language:before {
	top: 49%;
	left: 50%;
	z-index: -1;
	height: 60px;
	content: " ";
	aspect-ratio: 1/1;
	position: absolute;
	transform: translate(-50%, -50%);
	background-image: url(../../public/assets/img/sparse-icon-ster-grijs.svg);
}

.wpml-ls-current-language .dropdown-menu {
	background: var(--wit);
}

.wpml-ls-current-language .dropdown-menu .dropdown-item {
	font-size: 12px;
}

.wpml-ls-menu-item .nav-link {
	font-size: 12px !important;
	color: var(--zwart) !important;
	padding-left: 0.75rem !important;
	padding-right: 1.25rem !important;
}

.wpml-ls-menu-item:hover .nav-link {
	background: inherit !important;
}

.wpml-ls-current-language {
	margin-left: 0 !important;
}

.wpml-ls-current-language .dropdown-menu {
	top: 2.5rem;
	background: transparent;
	position: absolute !important;
}

ul .wpml-ls-menu-item a {
	justify-content: center;
}


/* Submenu */
.dropdown-toggle::after {
	border: 0;
	right: 0.5rem;
	margin-left: 0;
	content: "\e5cf";
	position: absolute;
	font-family: 'Material Symbols Sharp';
}

.dropdown-menu {
	left: 0;
	margin: 0;
	padding: 0;
	border: none;
	min-width: 100%;
	border-radius: 0;
	background: var(--turquoise);
}

.dropdown-menu .nav-item {
	margin-left: 0 !important;
}

.dropdown-menu .dropdown-item {
	font-size: 15px;
	padding: 0.5rem 1rem;
	font-family: "AlmarenaDisplayRegular";
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
	color: var(--white);
	background: var(--zwart);
}


/* Icon */
.sparse-icon {
	top: 1.5rem;
	left: 1.5rem;
	position: relative;
}


/* Toggle */
.navbar .navbar-toggler {
	border: 0;
	top: 25px;
	padding: 0;
	right: 25px;
	width: 65px;
	height: 65px;
	z-index: 1160;
	position: absolute;
}

.navbar .navbar-toggler span {
	top: 50%;
	width: 30px;
	height: 2px;
	margin: auto;
	display: block;
	position: relative;
	transform: translateY(-50%);
	background-color: var(--zwart);
}

.navbar .navbar-toggler span::before,
.navbar .navbar-toggler span::after {
	left: 0;
	width: 30px;
	height: 2px;
	content: " ";
	position: absolute;
	background-color: var(--zwart);
}

.navbar .navbar-toggler span::before {
	top: -10px;	
	transform: rotate(0deg);
}

.navbar .navbar-toggler span::after {
	top: 10px;
	transform: rotate(0deg);
}

.navbar.small .navbar-toggler {
	top: 0;
	right: 5px;
}


	/* Opened */
	.navbar-toggler.open-nav span {
		background-color: transparent !important;
	}

	.navbar-toggler.open-nav span::before {
		top: 50%;
		transform: rotate(45deg);
	}

	.navbar-toggler.open-nav span::after {
		top: 50%;
		transform: rotate(-45deg);
	}
	
	
	@media only screen and (max-width: 992px) {
			
		
		/*  2.0 ----------------- Header and navigation */
		/* Branding */
		.navbar .navbar-brand img {
			width: auto;
			height: 97px;
		}
		
		
		/* Navbar */
		.navbar {
			padding-left: 25px;
			padding-right: 25px;
		}
		
		.navbar.position-absolute.small {
			height: 65px;
		}
		
		.navbar .container {
			padding-top: 0;
			padding-bottom: 0;
		}
	
		
		/* Navigation */
		.nav-menu {
			top: 0;
			right: 0;
			bottom: 0;
			left: -100%;
			width: 100%;
			height: 100%;
			position: fixed;
			background: var(--turquoise);
		}
		
		.navbar-nav {
			top: 50%;
			position: relative;
			text-align: center !important;
			transform: translateY(-50%);
		}
		
		.navbar-nav .nav-item:not(:first-of-type) {
			margin-left: 0;
		}
		
		.nav-menu.open-nav {
			left: 0;
		}
		
		.navbar-nav .nav-item .nav-link {
			line-height: 43px;
		}
		
	}
	
	
	
	@media only screen and (max-width: 768px) {
		
		
		
		
		/*  2.0 ----------------- Header and navigation */
		/* Branding */
		.navbar .navbar-brand img {
			width: auto;
			height: 97px;
		}
		
		
		/* Navbar */
		.navbar {
			padding-left: 25px;
			padding-right: 25px;
		}
		
		.navbar.position-absolute.small {
			height: 65px;
		}
		
		.navbar .container {
			padding-top: 0;
			padding-bottom: 0;
		}
	
		
		/* Navigation */
		.nav-menu {
			top: 0;
			right: 0;
			bottom: 0;
			left: -100%;
			width: 100%;
			height: 100%;
			position: fixed;
			background: var(--turquoise);
		}
		
		.navbar-nav {
			top: 50%;
			position: relative;
			text-align: center;
			transform: translateY(-50%);
		}
		
		.navbar-nav .nav-item:not(:first-of-type) {
			margin-left: 0;
		}
		
		.nav-menu.open-nav {
			left: 0;
		}
		
		.navbar-nav .nav-item .nav-link {
			font-size: 18px;
			position: relative;
			display: inline-block;
			line-height: 1.5 !important;
			padding: 0.5rem 0 !important;
		}
		
		.navbar-nav .nav-item.current-page-ancestor .nav-link {
			color: var(--zwart);
		}
		
		.dropdown-toggle::after {
			right: -1.5rem;
		}
		
		.navbar-nav .dropdown-menu {
			text-align: center;
			margin-bottom: 1rem;
		}

		.navbar-nav .nav-item .nav-link {
			line-height: 43px;
		}		
		
	}
	
	@media only screen and (max-width: 1200px) {
		
		
		/* Navbar */
		.navbar .navbar-brand img.sparse-powerconnector {
			margin-bottom: -3rem;
			transform: none !important;
			position: relative !important;
		}
		
		.navbar-shop .container {
			overflow: visible;
		}
		
		.navbar-nav .nav-item .nav-link {
			line-height: 43px;
		}


		/*  2.0 ----------------- Header and navigation */
		/* Branding */
		.navbar .navbar-brand img {
			width: auto;
			height: 97px;
		}
		
		
		/* Navigation */
		.navbar-nav .nav-item:not(:first-of-type) {
			margin-left: 1rem;
		}
		
		.navbar-nav .nav-item .nav-link {
			font-size: 16px;
		}
		
	
	}
	
	
	
	