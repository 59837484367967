.teamleaderfocus-connector {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--oranje);
    display: flex;
    flex-direction: column;

    .add-container {
        flex-grow: 1;

        label {
            color: var(--wit);
            font-size: 1rem;
            font-weight: 400;
        }
    
        .error-info {
            border-radius: var(--border-radius);
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
}
