/*  1.0 ----------------- Typografie */
/* Font Family: Almarena */  
@font-face {
	font-family: "AlmarenaLight";
	src: url('../../public/assets/fonts/AlmarenaLight.woff2') format('woff2'),
		 url('../../public/assets/fonts/AlmarenaLight.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: "AlmarenaDisplayLight";
	src: url('../../public/assets/fonts/AlmarenaDisplayLight.woff2') format('woff2'),
		 url('../../public/assets/fonts/AlmarenaDisplayLight.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: "AlmarenaRegular";
	src: url('../../public/assets/fonts/AlmarenaRegular.woff2') format('woff2'),
		 url('../../public/assets/fonts/AlmarenaRegular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: "AlmarenaDisplayRegular";
	src: url('../../public/assets/fonts/AlmarenaDisplayRegular.woff2') format('woff2'),
		 url('../../public/assets/fonts/AlmarenaDisplayRegular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: "AlmarenaBold";
	src: url('../../public/assets/fonts/AlmarenaBold.woff2') format('woff2'),
		 url('../../public/assets/fonts/AlmarenaBold.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: "AlmarenaDisplayBold";
	src: url('../../public/assets/fonts/AlmarenaDisplayBold.woff2') format('woff2'),
		 url('../../public/assets/fonts/AlmarenaDisplayBold.woff') format('woff');
	font-display: swap;
}


/* Font Family: Icomoon */
@font-face {
	font-family: 'icomoon';
    src: url('../../public/assets/fonts/icomoon.woff?x3z37d') format('woff');
	// src:  url('/assets/fonts/Icomoon/icomoon.eot?x3z37d');
	// src:  url('/assets/fonts/Icomoon/icomoon.eot?x3z37d#iefix') format('embedded-opentype'),
	// 	  url('/assets/fonts/icomoon.woff?x3z37d') format('woff'),
		//   url('/assets/fonts/Icomoon/icomoon.svg?x3z37d#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}


/* Font Family: Mark Pro */

@font-face {
    font-family: 'MarkPro';
    src: url('../../public/assets/fonts/MarkPro.woff2') format('woff2'), url('../../public/assets/fonts/MarkPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Heavy';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Heavy.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Bold';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Bold_Italic';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Bold.Italic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Medium';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Medium.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Medium_Italic';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Medium.Italic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Black';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Black.otf') format('opentype');
    font-display: swap;
}

body {
    color: var(--zwartblauw);
    font-family: 'AlmarenaRegular' !important;    
}

body strong {
	font-weight: normal;
	font-family: "AlmarenaBold";
}

.font-regular {
    font-family: 'MarkPro' !important;
}

.font-heavy {
    font-family: 'MarkPro_Heavy' !important;
}

.font-bold {
    font-family: 'MarkPro_Bold' !important;
}

.font-bold-italic {
    font-family: 'MarkPro_Bold_Italic' !important;
}

.font-medium {
    font-family: 'MarkPro_Medium' !important;
}

.font-medium-italic {
    font-family: 'MarkPro_Medium_Italic' !important;
}

.font-black {
    font-family: 'MarkPro_Black' !important;
}