.form-card {
    &.detail-card {
        .card-body {
            padding: 1rem 2rem 2rem 2rem;

            ul {
                padding: 0;
                line-height: 1.7;
                font-size: 14px;
                text-transform: none !important;
            }

            .setting-header {
                font-family: var(--font-bold);
                color: var(--zwartblauw);
                font-size: 1rem;
            }

            label {
                color: var(--donkerblauw);
                font-family: var(--font-bold);
                font-size: 0.875rem;
            }

            .card-text .row:not(:last-of-type) {
                border-bottom: none;
            }

            .card-text .setting:not(:last-of-type) {
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(12, 23, 50, 0.1);
            }
        }
    }
}

.company-overview {

    .identity-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: var(--border-radius);

        .culture,
        .core-values,
        .identity-values {
            max-height: 500px;
            overflow-y: auto;
        }

        .pill {
            background-color: var(--wit);
            border-radius: var(--border-radius);
            position: relative;
            text-align: center;
            border: 3px solid var(--zwartblauw);
            margin: 1rem 0;
            width: 100%;

            &.view {
                padding: 12px 3rem;
                font-size: 1.25rem;
                font-family: var(--font-bold-italic);

                .info {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 2px 14px;
                    background-color: rgba(0, 0, 0, 0.08);
                    border-radius: 50%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.15);
                    }
                }
            }

            &.edit {
                padding: 8px 3rem;
                font-size: 1rem;
                font-family: var(--font-medium-italic);

                &.selected {
                    background-color: var(--geel);
                }

                .info {
                    position: absolute;
                    top: 6px;
                    right: 6px;
                    padding: 2px 11px;
                    background-color: rgba(0, 0, 0, 0.08);
                    border-radius: 50%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }
    }

    .background-colored-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 55%;
        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
        height: 100%;
        background-color: var(--paars);

        @media (max-width: 992px) {
            width: 100%;
            height: 50%;
            clip-path: none;
        }
    }
}
