@import "~bootstrap/scss/bootstrap-utilities";
@import "variables";

/* Global styles */
/* Body */
body.noscroll,
html.noscroll {
	overflow-y:hidden;
	touch-action: none;
	-ms-touch-action: none;
}


/* Transitions */
.transition,
.navbar.fixed-top.sticky,
.navbar .navbar-toggler span::after,
.navbar-toggler.open-nav span::after,
.navbar .navbar-toggler span::before,
.navbar-toggler.open-nav span::before {	
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
}

.transition-fast,
.faq-wrap .faq-toggle::after,
.faq-wrap .faq-toggle.collapsed::after {	
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
}


/* Achtergrondkleuren */
.bg-wit {
	background-color: var(--wit);
}

.bg-zwart {
	background-color: var(--zwart);	
}

.bg-blauw {
	background-color: var(--blauw);	
}

.bg-paars {
	background-color: var(--paars);	
}

.bg-groen {
	background-color: var(--groen);	
}

.bg-turquoise {
	background-color: var(--turquoise);
}

.bg-lichtgrijs {
	background-color: var(--lichtgrijs);
}

.bg-lichtgroen {
	background-color: var(--lichtgroen);
}

.bg-donkergrijs {
	background-color: var(--donkergrijs);
}

.bg-donkergroen {
	background-color: var(--donkergroen);
}


	/* Gradients */
	.bg-gradient {
		background-size: cover;
		background-position: center;
		background-image: url(../../public/assets/img/sparse-gradient-default.webp);
	}

	.bg-gradient.reversed {
		background-size: cover;
		background-position: center;
		background-image: url(../../public/assets/img/sparse-gradient-default-reversed.webp);
	}

	.bg-gradient-dark {
		background-size: cover;
		background-position: center;
		background-image: url(../../public/assets/img/sparse-gradient-donker.webp);
	}

	.bg-gradient-light {
		background-size: cover;
		background-position: center;
		background-image: url(../../public/assets/img/sparse-gradient-light.webp);
	}

	.bg-gradient-turquoise {
		position: relative;
		background-size: cover;
		background-position: center;
		background-image: url(../../public/assets/img/sparse-gradient-light-reversed.webp);
	}

	.bg-gradient-turquoise::before {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: " ";
		position: absolute;
		pointer-events: none;
		mix-blend-mode: multiply;
		background: var(--turquoise);
	}


	/* Noise */
	.bg-noise {
		position: relative;
	}

	.bg-noise::before {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		content: " ";
		position: absolute;
		pointer-events: none;
		background-size: 300px;
		background-repeat: repeat;
		mix-blend-mode: soft-light;
		background-image: url(../../public/assets/img/noise.svg);
	}


	/* Stippen */
	.bg-stippen {
		position: relative;
	}

	.bg-stippen::after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		content: " ";
		position: absolute;
		pointer-events: none;
		background-size: 75px;
   	 	background-repeat: repeat;
		background-image: url(../../public/assets/img/dots-groen.svg);
		max-width: calc((100% - 1140px) / 2 + (1140px / 12 * 1));
	}

	.bg-dots {
		right: 0;
		width: 400px;
		content: " ";
		aspect-ratio: 1/1;
		position: absolute;
		pointer-events: none;
		background-size: 90px;
		background-repeat: repeat;
		background-position: center;
		background-image: url(../../public/assets/img/dots-groen.svg);
	}

	.bg-dots.dots-top {
		bottom: 200px;
	}

	.bg-dots.dots-bottom {
		top: 0;
		max-height: 400px
	}


/* Skews */
.skew-recht-bottom {
	padding-bottom: 6rem !important;
}

.skew-left,
.row-module.skew-left {
	padding-top: 7.5rem !important;
	padding-bottom: 7.5rem !important;
	clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
}

.skew-left-top,
.row-module.skew-left-top {
	padding-top: 7.5rem !important;
	clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}

.skew-left-bottom,
.row-module.skew-left-bottom {
	padding-bottom: 7.5rem !important;
	clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}

.skew-right,
.row-module.skew-right {
	padding-top: 7.5rem !important;
	padding-bottom: 7.5rem !important;
	clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
}

.skew-right-top,
.row-module.skew-right-top {
	padding-top: 7.5rem !important;
	clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
}

.skew-right-bottom,
.row-module.skew-right-bottom {
	padding-bottom: 7.5rem !important;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}




[class^="icon-"], [class*=" icon-"] {
	speak: never;
	line-height: 1;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-family: 'icomoon' !important;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


	/* Iconen */
	.icon-twitter:before {
	  content: "\e909";
	}

	.icon-instagram:before {
	  content: "\e90c";
	}

	.icon-linkedin:before {
	  content: "\e90b";
	}

	.icon-play:before {
	  content: "\e900";
	}

	.icon-sparse:before {
	  content: "\e901";
	}

	.icon-arrow-down:before {
	  content: "\e902";
	}

	.icon-arrow-left:before {
	  content: "\e903";
	}

	.icon-arrow-right:before {
	  content: "\e904";
	}

	.icon-arrow-up:before {
	  content: "\e905";
	}

	.icon-sparse.icon-paars {
		color: var(--paars);
	}

	.icon-sparse.icon-groen {
		color: var(--groen);
	}

	.icon-sparse.icon-blauw {
		color: var(--blauw);
	}


/* Basics */
body {
	color: var(--zwart);
	font-family: "AlmarenaRegular";
}

body strong {
	font-weight: normal;
	font-family: "AlmarenaBold";
}

body p {
	font-size: 17px;
	line-height: 1.8;
	margin-bottom: 2rem;
}

body p:last-of-type {
	margin-bottom: 0;
}

body p + .wpcf7 {
	margin-top: 2rem;
}


	/* Hyperlinks */
	body p {
		font-size: 16px;
		line-height: 1.8;
		margin-bottom: 1rem;
	}


		/* List items */
		body ul,
		body ol {
			font-size: 15px;
			line-height: 1.8;
			padding-left: 1.25rem;
		}
	
		body p + ul,
		body p + ol {
			margin-top: -0.5rem;
		}
	
		/* Blockquote */
		body blockquote {
			margin: 2rem auto;
			padding: 1.5rem 1.75rem;
		}
	
		/* Image */
		body p img.img-fluid.size-full {
			margin-left: -25px;
			max-width: calc(100% + 50px);
		}
	



/* Headings */
h1, .h1,
h2, .h2,
h3, .h3 {
	line-height: 1.0;
	margin-bottom: 0;
	font-family: "AlmarenaDisplayBold";
}

h4, .h4,
h5, .h5,
h6, .h6 {
	line-height: 1.0;
	margin-bottom: 0;
	font-family: "AlmarenaDisplayRegular";
}

h1, .h1 {
	font-size: 52px;
	margin-bottom: 1rem;
}

h2, .h2 {
	font-size: 34px;
}

h2.text-large, .h2.text-large {
	font-size: 52px;
}

h2 + p, .h2 + p,
h2 + ul, .h2 + ul,
h2 + h3, .h2 + h3 {
    margin-top: 1.5rem;
}

h3, .h3 {
	font-size: 22px;
	line-height: 1.3;
}

h3 + p, .h3 + p {
    margin-top: 0.5rem;
}

h4, .h4 {
	font-size: 20px;
	line-height: 1.3;
}

h4 + p, .h4 + p {
    margin-top: 1rem;
}

h5, .h5 {
	font-size: 18px;
	line-height: 1.4;
}


/* Font colors */
.text-wit {
	color: var(--wit);
}
 
.text-zwart {
	color: var(--zwart);	
}

.text-blauw {
	color: var(--blauw);	
}

.text-paars {
	color: var(--paars);	
}

.text-groen {
	color: var(--groen);	
}

.text-turquoise {
	color: var(--turquoise);	
}

.text-lichtgroen {
	color: var(--lichtgroen);
}

.text-donkergroen {
	color: var(--donkergroen);
}

