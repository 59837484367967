:root {
    --wit: #ffffff;
	--grijs: #cccccc;
	--zwart: #000000;
	--blauw: #0000fc !important;
	--paars: #d91eff !important;
	--groen: #0ec6a4;
	--turquoise: #1aefc8;
	--lichtgrijs: #f7f7f7;
	--lichtgroen: #a3f9e9;
	--donkergrijs: #333333;
	--donkergroen: #006f6f;
	
	--rgbwit: 255, 255, 255;
	--rgbzwart: 0, 0, 0;
    --donkerblauw: #041db7;
    --lichtblauw: #c9edf8;
    --oranje: #0246FD;
    --zwartblauw: #0c1732;
    --lila: #e0d9fb;
    --roze: #D786FF;
    --lichtgeel: #fcffce;
    --geel: #2BF1CD;
    --donkergeel: #dcaf22;
   
    --success: #78c1a3;
    --danger: #f38989;

    --btn-border-radius: 50px;
    --border-radius: 25px;
    --navbar-width: 4.375rem;
    --navbar-mobile-height: 90px;
    --navbar-margin: 16px;

    --font-heavy: "MarkPro_Heavy";
    --font-bold: "AlmarenaDisplayBold";
    --font-bold-italic: "MarkPro_Bold_Italic";
    --font-medium: "MarkPro_Medium";
    --font-medium-italic: "MarkPro_Medium_Italic";
    --font-black: "MarkPro_Black";
}