.public {
    ol {
        padding-left: 20px;
        list-style: number;
        margin: 20px 0;

        li:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .link {
        text-decoration: underline;

        &:hover, &:focus {
            color: var(--oranje);
        }
    }

    .img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 25px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.left {
            border-radius: 25px 0 0 25px;
        }

        &.right {
            border-radius: 0 25px 25px 0;
        }

        @media (max-width: 768px) {
            border-radius: 25px 25px 0 0;
            max-height: 80vw;
            position: relative !important;

            &.left {
                border-radius: 25px 25px 0 0;
            }

            &.right {
                border-radius: 0 0 25px 25px;
            }
        }
    }

    p {
        line-height: 1.7;
    }

    .textblock {
        background-color: var(--wit);
        border-radius: 25px;

        h2 {
            font-size: 2.25rem;
            font-family: var(--font-heavy);
        }

        &.left {
            border-radius: 25px 0 0 25px;
        }

        &.right {
            border-radius: 0 25px 25px 0;
        }

        &.full {
            padding-left: 25%;
            padding-right: 25%;
        }

        @media (max-width: 768px) {
            &.left {
                border-radius: 25px 25px 0 0;
            }

            &.right {
                border-radius: 0 0 25px 25px;
            }

            &.full {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }
}

.home {

    .testimonial-slide {
        img {
            height: 420px;
            width: 420px;
            object-fit: cover;

            @media (max-width: 992px) {
                height: 300px;
                width: 300px;
            }
        }

        .testimonial {
            font-family: var(--font-bold);
            font-size: 1.375rem;
            line-height: 1.3;
            font-style: italic;

            @media (max-width: 770px) {
                font-size: 1.125rem;
            }
        }

        @media (max-width: 770px) {
            flex-direction: column;
        }
    }
}

.faq {
    h2 {
        font-size: 1.75rem;
    }

    .accordion-item,
    .accordion-header {
        border-radius: 25px;
        border: none;
    }

    .accordion-item {
        margin-bottom: 1rem;

        button {
            border: 2px solid var(--wit);
            border-radius: 25px !important;
            color: var(--donkerblauw);
            font-family: var(--font-bold);

            &:not(.collapsed) {
                box-shadow: none;
                background-color: var(--wit);
            }

            &:focus {
                box-shadow: none;
                outline: none;
                border-color: var(--donkerblauw);
            }
        }
    }
}

.contact {
    .form-label {
        font-size: 0.875rem;

        .required {
            margin-left: 8px;
            font-size: 0.75rem;
            color: var(--oranje);
            font-family: var(--font-medium-italic);
        }
    }

    .address {
        padding-left: 8px;
        border-left: 3px solid rgba(0, 0, 0, 0.2);
    }
}

.testimonials {
    .testimonial-card {
        border-radius: 25px;
        background: var(--wit);
        height: 100%;
        margin: 0 1rem;

        .card-img {
            width: 100%;
            height: auto;

            img {
                border-radius: 25px 25px 0 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-body {
            padding: 2rem;

            h2 {
                color: var(--donkerblauw);
                font-family: var(--font-heavy);
                font-size: 1.25rem;
                margin-bottom: 0.5rem;
            }

            h3 {
                font-family: var(--font-bold);
                font-size: 1rem;
                margin-bottom: 1.5rem;
            }
        }
    }
}

.initiators {
    .initiator-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: var(--wit);
        border-radius: var(--border-radius);

        .card-img {
            height: 70px;
            border-radius: 25px 25px 0 0;
            padding: 2rem 2rem 0 2rem;

            img {
                height: 100%;
                width: auto;
                object-fit: cover;
            }
        }

        .card-body {
            flex-direction: column;
            padding: 2rem 2rem 1.5rem 2rem;

            h3 {
                font-family: var(--font-heavy);
                font-size: 1.75rem;
                margin-bottom: 0.75rem;
            }
        }

        .card-footer {
            padding: 0.5rem 2rem 2rem 2rem;

            a {
                text-transform: none;
                font-family: var(--font-heavy);
                font-size: 0.875rem;
            }
        }
    }
}

.partners {
    .textblock {
        h2 {
            font-size: 1.75rem;
        }
    }

    .logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        img {
            padding: 8px;
            max-height: 80px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            border-radius: 8px;

            @media (max-width: 768px) {
                max-height: 60px;
            }
        }
    }
}

.talent-development {
    .approach {
        li {
            margin-bottom: 16px;

            span:first-of-type {
                font-family: var(--font-bold);
                margin-right: 6px;
            }
        }
    }

    .logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        img {
            padding: 8px;
            max-height: 80px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            border-radius: 8px;

            @media (max-width: 768px) {
                max-height: 60px;
            }
        }
    }
}

.traineeships {
    .info {
        h2 {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        &:not(:first-of-type) {
            margin-top: 2rem;
        }
    }
}