/* Buttons */
/* Buttons */
.btn {
	font-size: 15px;
	min-height: 50px;
	line-height: 2.0;
	border-radius: 0;
	border: 2px solid;
	position: relative;
	letter-spacing: 0.05rem;
	outline: none !important;
	font-family: "AlmarenaBold";
	padding: 0.5rem 1.1rem 0.4rem 1.1rem;
}

.btn:not(:last-of-type) {
	margin-right: 1rem;
	margin-bottom: 0.5rem;
}

.btn:not(:first-of-type) {
	margin-bottom: 0.5rem;
}


	/* Button styles */
	.btn.btn-primary {
		color: var(--wit) !important;
		border-color: var(--zwart) !important;
		background-color: var(--zwart) !important;
	}

	.btn.btn-primary:hover,
	.btn.btn-primary:focus,
	.btn.btn-primary:active {
		box-shadow: 10px 10px 0 0 var(--turquoise);
	}

	.btn.btn-secondary {
		color: var(--zwart) !important;
		border-color: var(--wit) !important;
		background-color: var(--wit) !important;
	}

	.btn.btn-secondary:hover,
	.btn.btn-secondary:focus,
	.btn.btn-secondary:active {
		box-shadow: 10px 10px 0 0 var(--turquoise);
	}

	.btn.btn-paars {
		color: var(--wit) !important;
		border-color: var(--paars) !important;
		background-color: var(--paars) !important;
	}

	.btn.btn-paars:hover,
	.btn.btn-paars:focus,
	.btn.btn-paars:active {
		box-shadow: 10px 10px 0 0 var(--turquoise);
	}

	.btn.btn-outline-wit {
		color: var(--wit);
		border-color: var(--wit);
		background-color: transparent;
	}

	.btn.btn-outline-wit:hover,
	.btn.btn-outline-wit:focus,
	.btn.btn-outline-wit:active {
		color: var(--zwart) !important;
		border-color: var(--wit) !important;
		background-color: var(--wit) !important;
		box-shadow: 10px 10px 0 0 var(--turquoise) !important;
	}

	.btn.btn-outline-zwart {
		color: var(--zwart);
		border-color: var(--zwart);
		background-color: transparent;
	}

	.btn.btn-outline-zwart:hover,
	.btn.btn-outline-zwart:focus,
	.btn.btn-outline-zwart:active {
		color: var(--wit) !important;
		border-color: var(--zwart) !important;
		background-color: var(--zwart) !important;
		box-shadow: 10px 10px 0 0 var(--turquoise) !important;
	}

/*
	.btn-internal {
		/*flex: 0 0 160px;
		font-size: 18px;
		text-align: center;
		color: var(--zwart);
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.btn-internal:hover,
	.btn-internal:focus,
	.btn-internal:active {
		color: var(--zwart);
	}

	.btn-internal:last-of-type span {
		display: none;
	}
*/





@media only screen and (max-width: 576px) {
	


/* Button */
.btn.btn-order {
	border: 0;
	min-height: 1px;
	font-weight: 400;
	position: relative;
	border-radius: 100px;
	color: var(--blauw);
	padding: 0.5rem 3rem 0.4rem 1.1rem;
	background-color: rgba(0, 0, 252, 0.1);
}

.btn.btn-order span {
	top: 50%;
	right: 4px;
	display: flex;
	height: calc(100% - 8px);
	color: var(--wit);
	aspect-ratio: 1/1;
	position: absolute;
	border-radius: 100px;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);
	background-color: rgba(0, 0, 252, 1);
}


/* Pagination */
.btn.btn-pagination {
	color: var(--blauw);
	border-color: var(--blauw);
	width: 50px;
	height: 50px;
	line-height: 50px;
	padding: 0;
	text-align: center;
	border-radius: 50px;
	background: var(--wit);
}

.btn.btn-pagination:hover,
.btn.btn-pagination:focus {
	color: var(--wit);
	background: var(--blauw);
}

.btn.btn-pagination span {
	height: 100%;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}
}