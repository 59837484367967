.footer {
    .btn.btn-oranje {
        color: var(--wit);
        background: var(--oranje);
        border-color: var(--oranje);
    }

    .btn {
        font-size: 12px;
        font-weight: 900;
        line-height: 1.0;
        border-width: 2px;
        position: relative;
        border-style: solid;
        border-radius: 50px;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        box-shadow: none !important;
        text-decoration: none !important;
        padding: 0.8rem 2.25rem 0.8rem 1rem;
    }

    .btn:not(:last-of-type) {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
    }

    .btn:not(:first-of-type) {
        margin-bottom: 0.5rem;
    }

    .btn span {
        top: 48%;
        right: 0.5rem;
        font-size: 14px;
        position: absolute;
        transform: translateY(-50%);
    }

    .voortgang-bar {
        gap: 8rem;
        color: var(--wit);
    }

    .voortgang-bar-compact {
        gap: 2rem;
        color: var(--wit);
        margin-bottom: 2rem;
    }

    .voortgang-bar .voortgang-item {
        width: 100px;
        position: relative;
    }

    .voortgang-bar-compact .voortgang-item {
        position: relative;
    }

    .voortgang-bar-compact .voortgang-item::after,
    .voortgang-bar .voortgang-item::after {
        top: 14px;
        left: calc(100% - 25px);
        width: calc(8rem + 50px);
        height: 2px;
        content: " ";
        position: absolute;
        background: rgba(255, 255, 255, 0.2);
    }

    .voortgang-bar-compact .voortgang-item::after {
        left: calc(100% + 5px);
        width: calc(2rem - 10px);
    }

    .voortgang-bar .voortgang-item.current::after {
        background: var(--groen);
    }

    .voortgang-bar-compact .voortgang-item.current::after {
        background: var(--oranje);
    }

    .voortgang-bar-compact .voortgang-item:last-of-type::after,
    .voortgang-bar .voortgang-item:last-of-type::after {
        display: none;
    }

    .voortgang-bar-compact .voortgang-status,
    .voortgang-bar .voortgang-status {
        height: 30px;
        line-height: 20px !important;
        font-size: 12px;
        padding: 0.333rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        text-align: center;
        background: rgba(255, 255, 255, 0.2);
    }

    .voortgang-bar .current .voortgang-status {
        background: var(--groen);
    }

    .voortgang-bar-compact .current .voortgang-status {
        background: var(--oranje);
    }

    .voortgang-bar .voortgang-omschrijving {
        font-size: 12px;
        font-weight: 500;
        margin-top: 0.25rem;
    }
}