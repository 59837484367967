
.product-label.product-new {
    top: 0;
    right: 0;
    width: 100px;
    display: flex;
    font-weight: 700;
    aspect-ratio: 1 / 1;
    color: var(--wit);
    align-items: center;
    justify-content: center;
    background: var(--paars);
    text-transform: uppercase;
    transform: translate(33.333%, -33.333%) rotate(15deg);
    -webkit-mask-image: url(../../../public/assets/img/sparse-icon-ster.svg);
    mask-image: url(../../../public/assets/img/sparse-icon-ster.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.product-label {
    z-index: 3;
    position: absolute;
}

.btn.btn-order {
    border: 0;
    min-height: 1px;
    font-weight: 400;
    position: relative;
    border-radius: 100px;
    color: var(--blauw);
    padding: 0.5rem 3rem 0.4rem 1.1rem;
    background-color: rgba(0, 0, 252, 0.1);
    line-height: 30px;
}

    .form-check {
        white-space:nowrap;
        padding-right: 10px;
    }

    .add-function-card {
        border: 2px dashed var(--wit);
        border-radius: var(--border-radius);
        min-height: 296px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        &:hover, &:focus {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .add-function-icon {
            background-color: var(--geel);
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .add-function-text {
            color: var(--wit);
        }
    }

    .background-colored-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 70vw;
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        height: 60vh;
        background-color: var(--paars);

        @media screen and (max-width: 768px) {
            width: 100vw;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            height: 75vh;
        }
    }

    .filters {
        display: flex !important;
        gap: 1rem;

        .filter-toggle button {
            background-color: rgba(0, 0, 0, 0.15);
            border: 3px transparent solid;
            font-family: var(--font-medium);
            color: rgba(0, 0, 0, 0.6);
            border-radius: var(--border-radius);
            padding: 0.5rem 1rem;
            line-height: 1;

            &.selected {
                color: var(--zwartblauw);
                background-color: var(--geel);
                border: 3px solid var(--geel);
            }
        }
    }

.add-function-modal {
    .modal-content {
        border: 0;
        border-radius: 25px;
        background-color: var(--wit);

        .modal-header {
            padding: 1.5rem;
            border: 0;
            border-radius: 25px 25px 0 0;
            display: flex;
            justify-content: center;
        }

        .modal-body {
            padding: 1.5rem;

            form > div {
                margin: 0;
                border-radius: 25px;
                padding: 1rem 1rem 1.5rem 1rem;
                background: rgba(125, 119, 255, 0.1);

                &:not(:last-of-type) {
                    margin-bottom: 1.5rem;
                }
            }

            .btn-delete {
                padding: 0;
                width: 40px;
                height: 40px;
            }
        }

        .modal-footer {
            border: 0;
            border-radius: 0 0 25px 25px;
        }
    }
}
