@import "~bootstrap/scss/bootstrap-utilities";


.sticky {
    position: sticky;
    top: -1px;
    z-index: 1000 !important;
    // width: 100%;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;
  }
  
  @keyframes smoothScroll {
    0% {
      transform: translateY(-142px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }

button.dropdown-toggle.nav-link {
    background-color: white  !important;
    border: none !important;
    color: black !important;
}
.navigation-bar {
    z-index: 100000000;

    & {
        padding: 0.5rem;
        background-color: var(--wit);
        position: fixed;

        // Mobile
        @include media-breakpoint-only(xs) {
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: var(--navbar-mobile-height);
        }

        // Other devices
        @include media-breakpoint-up(sm) {
            width: var(--navbar-width);
            top: 10vh;
            border-radius: 5rem;
            height: fit-content;
            left: var(--navbar-margin);
        }

        // Wide screens
        @media screen and (min-width: 2000px) {
            left: initial;
            margin-left: -70px+-32px;
        }
    }

    .navigation-bar-nav {
        height: 100%;
        display: flex;
        flex-direction: row;

        // Other devices
        @include media-breakpoint-up(sm) {
            flex-direction: column;
        }

        .navigation-bar-user {
            text-align: center;
        }

        .navigation-bar-links {
            flex-grow: 1;

            // Mobile
            @include media-breakpoint-only(xs) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .more-button {
                    cursor: pointer;
                }
    
                .mobile-popup {
                    &.open {
                        display: flex !important;
                        flex-direction: column;
                        gap: 1.5rem;
                        position: absolute;
                        height: fit-content;
                        width: 25%;
                        background-color: var(--wit);
                        border-radius: var(--border-radius) var(--border-radius) 0 0;
                        padding: 2rem 1rem;
                        top: -320px;
                        bottom: 0;
                        right: 0;
                        z-index: 90;
                    }
                }
            }

        }

        .navigation-bar-utilities {
            // Other devices
            @include media-breakpoint-up(sm) {
                margin-bottom: 1rem;
            }
        }

        li {
            text-align: center;
            flex: 25%;

            a {
                display: flex;
                align-items: center;
                flex-direction: column;

                &.active {
                    color: var(--oranje);

                    svg {
                        fill: var(--oranje);
                    }
                }
            }

            &:not(:last-child) {
                // Other devices
                @include media-breakpoint-up(sm) {
                    margin-bottom: 1rem;
                }
            }

            &:last-child {
                @include media-breakpoint-up(sm) {
                    margin-top: 3rem;
                }
            }

            span {
                font-size: 0.625rem;
                padding-top: 0.25rem;

                + span {
                    padding-top: 0;
                }
            }
        }
    }
}




/* Toggle */
.navbar .navbar-toggler {
	border: 0;
	top: 25px;
	padding: 0;
	right: 25px;
	width: 65px;
	height: 65px;
	z-index: 1160;
	position: absolute;
}

.navbar .navbar-toggler span {
	top: 50%;
	width: 30px;
	height: 2px;
	margin: auto;
	display: block;
	position: relative;
	transform: translateY(-50%);
	background-color: var(--zwart);
}

.navbar .navbar-toggler span::before,
.navbar .navbar-toggler span::after {
	left: 0;
	width: 30px;
	height: 2px;
	content: " ";
	position: absolute;
	background-color: var(--zwart);
}

.navbar .navbar-toggler span::before {
	top: -10px;	
	transform: rotate(0deg);
}

.navbar .navbar-toggler span::after {
	top: 10px;
	transform: rotate(0deg);
}

.navbar.small .navbar-toggler {
	top: 0;
	right: 5px;
}


	/* Opened */
	.navbar-toggler.open-nav span {
		background-color: transparent !important;
	}

	.navbar-toggler.open-nav span::before {
		top: 50%;
		transform: rotate(45deg);
	}

	.navbar-toggler.open-nav span::after {
		top: 50%;
		transform: rotate(-45deg);
	}


    /* Transitions */
.transition,
.navbar.fixed-top.sticky,
.navbar .navbar-toggler span::after,
.navbar-toggler.open-nav span::after,
.navbar .navbar-toggler span::before,
.navbar-toggler.open-nav span::before {	
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-ms-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
}

.transition-fast,
.faq-wrap .faq-toggle::after,
.faq-wrap .faq-toggle.collapsed::after {	
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
}


@media only screen and (max-width: 992px) {
	
	
	.col-stekker-img {
		text-align: center;
	}
	
	.col-stekker-img img {
		max-width: 200px;
	}
	
	.row-stekkers::after {
		top: -45px;
		left: 50%;
		transform: translateX(-83.333%);
	}
	
	.row-stekkers::before {
		top: -19px;
		left: 5px;
		border-top-left-radius: 10px;
		width: calc(50% - 10px);
	}
	
	.col-stekker {
		margin-left: 5px;
		padding-left: 45px;
		border-left: 4px solid #46b5ee;
	}
	
	.col-stekker:last-of-type {
		border-left: 0;
	}
	
	.col-stekker:last-of-type::before {
		left: 0;
	}
	
	.col-stekker:last-of-type::after {
		left: 15px;
	}
	
	.col-stekker::before {
		top: -15px;
		left: -4px;
		border-top: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 10px;
		border-bottom: 4px solid #46b5ee;
	}
	
	.col-stekker::after {
		top: 4.5px;
		left: 11px;
		border-radius: 1px 6px 6px 1px;
	}
	
	
	
	/*  2.0 ----------------- Header and navigation */
	/* Branding */
	.navbar .navbar-brand img {
		width: auto;
		height: 97px;
	}
	
	
	/* Navbar */
	.navbar {
		padding-left: 25px;
		padding-right: 25px;
	}
	
	.navbar.position-absolute.small {
		height: 65px;
	}
	
	.navbar .container {
		padding-top: 0;
		padding-bottom: 0;
	}

	
	/* Navigation */
	.nav-menu {
		top: 0;
		right: 0;
		bottom: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		position: fixed;
		background: var(--turquoise);
	}
	
	.navbar-nav {
		top: 50%;
		position: relative;
		text-align: center;
		transform: translateY(-50%);
	}
	
	.navbar-nav .nav-item:not(:first-of-type) {
		margin-left: 0;
	}
	
	.nav-menu.open-nav {
		left: 0;
	}
	
	
	
	/*  3.0 ----------------- Page elements */
	/* Page wrapper */
	.page-content {
		padding-top: 65px;
	}
	
	
}


@media only screen and (max-width: 768px) {
	
	.col-stekker-img {
		text-align: center;
	}
	
	.col-stekker-img img {
		max-width: 200px;
	}
	
	.row-stekkers::after {
		top: -45px;
		left: 50%;
		transform: translateX(-83.333%);
	}
	
	.row-stekkers::before {
		top: -19px;
		left: 5px;
		border-top-left-radius: 10px;
		width: calc(50% - 10px);
	}
	
	.col-stekker {
		margin-left: 5px;
		padding-left: 45px;
		border-left: 4px solid #46b5ee;
	}
	
	.col-stekker:last-of-type {
		border-left: 0;
	}
	
	.col-stekker:last-of-type::before {
		left: 0;
	}
	
	.col-stekker:last-of-type::after {
		left: 15px;
	}
	
	.col-stekker::before {
		top: -15px;
		left: -4px;
		border-top: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 10px;
		border-bottom: 4px solid #46b5ee;
	}
	
	.col-stekker::after {
		top: 4.5px;
		left: 11px;
		border-radius: 1px 6px 6px 1px;
	}
	
	
	/*  0.0 ----------------- Defaults */	
	/* Buttons */
	.btn {
		font-size: 14px;
	}
	
	/*
	.btn-internal {
		flex: auto;
		text-align: left;
		padding-left: 0;
		padding-right: 3rem;		
	}
	
	.btn-internal:last-of-type {
		padding-right: 25px;
		/*padding-right: calc(100vw - 110px);
	}
	*/
	
	
	/*  1.0 ----------------- Typografie */
	/* Basics */
	body p {
		font-size: 16px;
		line-height: 1.8;
		margin-bottom: 1rem;
	}


		/* List items */
		body ul,
		body ol {
			font-size: 15px;
			line-height: 1.8;
			padding-left: 1.25rem;
		}
	
		body p + ul,
		body p + ol {
			margin-top: -0.5rem;
		}
	
		/* Blockquote */
		body blockquote {
			margin: 2rem auto;
			padding: 1.5rem 1.75rem;
		}
	
		/* Image */
		body p img.img-fluid.size-full {
			margin-left: -25px;
			max-width: calc(100% + 50px);
		}
	

	/* Headings */
	h1, .h1 {
		font-size: 38px;
	}

	h2, .h2 {
		font-size: 30px;
	}
	
	h3, .h3 {
		font-size: 20px;
	}
	
	h4, .h4 {
		font-size: 20px;
	}

	
	
	/*  2.0 ----------------- Header and navigation */
	/* Branding */
	.navbar .navbar-brand img {
		width: auto;
		height: 97px;
	}
	
	
	/* Navbar */
	.navbar {
		padding-left: 25px;
		padding-right: 25px;
	}
	
	.navbar.position-absolute.small {
		height: 65px;
	}
	
	.navbar .container {
		padding-top: 0;
		padding-bottom: 0;
	}

	
	/* Navigation */
	.nav-menu {
		top: 0;
		right: 0;
		bottom: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		position: fixed;
		background: var(--turquoise);
	}
	
	.navbar-nav {
		top: 50%;
		position: relative;
		text-align: center;
		transform: translateY(-50%);
	}
	
	.navbar-nav .nav-item:not(:first-of-type) {
		margin-left: 0;
	}
	
	.nav-menu.open-nav {
		left: 0;
	}
	
	.navbar-nav .nav-item .nav-link {
		font-size: 18px;
		position: relative;
		display: inline-block;
		line-height: 1.5 !important;
		padding: 0.5rem 0 !important;
	}
	
	.navbar-nav .nav-item.current-page-ancestor .nav-link {
		color: var(--zwart);
	}
	
	.dropdown-toggle::after {
		right: -1.5rem;
	}
	
	.navbar-nav .dropdown-menu {
		text-align: center;
		margin-bottom: 1rem;
	}
	
	
	
	ul .wpml-ls-menu-item a {
		justify-content: center;
	}
	
	
	
	/*  3.0 ----------------- Page elements */
	/* Page header */
	.row-bg-header {
		height: 215px;
		position: relative;
	}
	
	
	/* Page wrapper */
	.page-content {
		padding-top: 65px;
	}

	
	/* Cases */
	.case {
		position: relative;
	}
	
	.container-swiper {
		padding-left: 0;
	}
	
	.container-swiper .swiper-wrapper {
		padding-left: 25px;
	}
	
	
		/* Navigation */
		.swiper-navigation {
			padding-right: 10px;
		}


		/* Cards */	
		.card-case a .card-body p,
		.card-case a:hover .card-body p {
			font-size: 14px;
		}
	
		.card-testimonial {
			padding: 1.5rem;
		}

	
	/* Skews */
	.skew-recht-bottom {
		padding-bottom: 3rem !important;
	}
	
	.skew-left,
	.row-module.skew-left {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
		clip-path: polygon(0 2.5%, 100% 0, 100% 97.5%, 0% 100%);
	}

	.skew-right,
	.row-module.skew-right {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
		clip-path: polygon(0 0, 100% 2.5%, 100% 100%, 0 97.5%);
	}
	
	.skew-left-top,
	.row-module.skew-left-top {
		padding-top: 3rem !important;
		clip-path: polygon(0 2.5%, 100% 0, 100% 100%, 0% 100%);
	}
	
	.skew-right-top,
	.row-module.skew-right-top {
		padding-top: 3rem !important;
		clip-path: polygon(0 0, 100% 2.5%, 100% 100%, 0 100%);
	}
	
	.skew-left-bottom,
	.row-module.skew-left-bottom {
		padding-bottom: 3rem !important;
		clip-path: polygon(0 0, 100% 0, 100% 97.5%, 0% 100%);
	}
	
	.skew-right-bottom,
	.row-module.skew-right-bottom {
		padding-bottom: 3rem !important;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97.5%);
	}
	
	
	/* Diensten */
	.row-module-koppelingen {
		padding-top: 4.5rem !important;
		margin-bottom: -3rem !important;
		padding-bottom: 6rem !important;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 98.75%) !important;
	}
	
	
	/* Cases */
	.row-module-cases {
		margin-bottom: 3rem;
	}
	
	
	/* Oplossingen */	
	.oplossingen ul li {
		font-size: 14px;
		padding-right: 1rem;
	}
	
	.sticky-oplossingen {
		overflow-x: auto;
  		white-space: nowrap;
	}
	
	.sticky-oplossingen .container::-webkit-scrollbar {
		display: none;
	}
	
	.sticky-oplossingen .container {
		overflow-x: scroll;
		
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	
	.row-oplossingen .row.min-vh-100 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
	
	
	/* Vacatures */
	.row-vacature {
		padding-top: 0;
		margin-top: -6rem;
	}

	
	/* Modules */
	.row-module {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.row-module:last-of-type {
		padding-bottom: 3rem;
	}
	
	
	/* Afbeeldingen */
	.case-logo {
		width: 100px;
		min-width: 100px;
	}
	
	.case-logo.border {
		min-width: 1px;
		width: auto !important;
	}
	
	.case-logo.border img {
		max-height: 30px;
	}


	/* Tag */
	.row-case-info {
		bottom: -21px;
	}
	
	

	/*  6.0 ----------------- Footer */	
	/* Footer */
	.footer-col {
		width: 50% !important;
	}
	
	.footer-logo {
		height: auto;
		max-width: 150px;
	}
	
	/* Ticker */
	.ticker {
		font-size: 13px;
	}
	
	/* Overlay */
	.footer-overlay {
		top: -100px;
		height: calc(100% + 100px);
		clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
	}
	
	
}


.open-nav {
    opacity: 100 !important;
}